import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
// const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/button-docs/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        // {
        //     path: '/',
        //     name: 'Home',
        //     component: Home
        // },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/color-helpers/',
            name: 'About',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/ColorHelpers')
        },
        {
            path: '/border-helpers/',
            name: 'Border',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/BorderHelpers')
        },
        {
            path: '/helpers-flex-alignment/',
            name: 'FlexAlignment',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/FlexAlignment')
        },
        {
            path: '/helpers-position-helpers/',
            name: 'PositionHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/PositionHelpers')
        },
        {
            path: '/helpers-display-helpers/',
            name: 'DisplayHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/DisplayHelpers')
        },
        {
            path: '/helpers-shadows-helpers/',
            name: 'ShadowsHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/ShadowsHelpers')
        },
        {
            path: '/helpers-width-helpers/',
            name: 'WidthHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/WidthHelpers')
        },
        {
            path: '/helpers-spaces/',
            name: 'SpacesHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/SpacesHelpers')
        },
        {
            path: '/helpers-z-index/',
            name: 'ZindexHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/ZindexHelpers')
        },
        {
            path: '/helpers-transforms/',
            name: 'TransformsHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/TransformsHelpers')
        },
        {
            path: '/helpers-entry-exit-animation/',
            name: 'EntryExitAnimation',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/EntryExitAnimation')
        },
        {
            path: '/helpers-image-and-background/',
            name: 'ImageAndBackgroundHelpers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/ImageAndBackgroundsHelpers')
        },
        {
            path: '/helpers-form-modifiers/',
            name: 'FormModifiers',
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/FormModifers')
        },
        {
            path: '/vue-table/',
            name: 'VueTable',
            component: () => import(/* webpackChunkName: "home" */'../views/tables/VueTableDocs')
        },
        {
            path: '/vue-table-card/',
            name: 'VueTableCard',
            component: () => import(/* webpackChunkName: "home" */'../views/tables/VueTableCardDocs')
        },
        {
            path: '/simple-table/',
            name: 'SimpleTable',
            component: () => import(/* webpackChunkName: "home" */'../views/tables/SimpleTableDocs')
        },

        // Dashboard Components
        {
            path: '/file-upload/',
            name: 'DragAndDropFileUploadComponentDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/ActivityGaugesDemoPage')
            component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/DragAndDropFileUploadComponentDocs')
        },
        {
            path: '/base-breadcrumb/',
            name: 'BaseBreadcrumb',
            // component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/ActivityGaugesDemoPage')
            // component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/docs/BreadcrumbComponentDemo')
            component: () => import(/* webpackChunkName: "home" */'../views/docs/BreadcrumbVariantsDocs')
        },
        {
            path: '/crud-items/',
            name: 'CrudItems',
            component: () => import(/* webpackChunkName: "home" */'../views/crud-items/CrudItemsPage')
        },
        {
            path: '/paginations/',
            name: 'Paginations',
            // component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/ActivityGaugesDemoPage')
            // component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/docs/BreadcrumbComponentDemo')
            component: () => import(/* webpackChunkName: "home" */'../views/docs/PaginationsVariantsDoc')
        },
        {
            path: '/progress-steps-docs/',
            name: 'ProgressStepsDemo',
            component: () => import(/* webpackChunkName: "home" */'../views/docs/ProgressStepsDemoUpdated')
        },
        {
            path: '/activity-gauge/',
            name: 'ActivityGaugesDocsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/activity-gauge/ActivityGaugesDocsPage')
        },
        {
            path: '/activity-gauge-customisable/',
            name: 'ActivityGaugeCustomizableDocsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/activity-gauge/ActivityGaugeCustomizableDocsPage')
        },
        {
            path: '/listing-card/',
            name: 'ListingCardItemsDocs',
            component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/ListingCardItemsDocs')
        },
        {
            path: '/metrics/',
            name: 'MetricsVariantsDocsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/MetricsVariantsDocsPage')
        },
        {
            path: '/metrics-two/',
            name: 'MetricsVariantsDocsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/dashboard-components/MetricsType2VariantsDocsPage')
        },

        // Web Components
        {
            path: '/team-avatar-variants/',
            name: 'TeamAvatarItem',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/TeamAvatarVariantsPage')
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/TeamAvatarItemSplittedVariantsPage')
        },
        {
            path: '/features-section-item.json-variants/',
            name: 'TeamAvatarItem',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/FeaturesSectionItemVariants')
        },
        {
            path: '/Testimonials-Variants/',
            name: 'TestimonialsVariantsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/TestimonialsVariantsPage')
        },
        {
            path: '/Testimonials-Variants/',
            name: 'TestimonialsItemsone',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/TestimonialsItemsone')
        },
        {
            path: '/Testimonials-Variants/',
            name: 'TestimonialItemTwo',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/TestimonialItemTwo')
        },
        {
            path: '/Matrics-Variants-Page/',
            name: 'MatricsVariantsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/MatricsVariantsPage')
        },
        {
            path: '/blog-item-card/',
            name: 'BlogItemCard',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/blog-item-card/BlogItemCardVariantsDocs')
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/blog-item-card/BlogItemCardVariantsSplittedDocs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/BlogItemVariantsSplittedDocs')
        },
        {
            path: '/Matrics-Item-One/',
            name: 'MatricsItemOne',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/MatricsItemOne')
        },
        {
            path: '/Matrics-Items-Two/',
            name: 'MatricsItemsTwo',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/MatricsItemsTwo')
        },
        {
            path: '/faq/',
            name: 'Faq',
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/FaqItemsVariantsDocs')
        },
        {
            path: '/price-cards/',
            name: 'PriceCards',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/PriceCards')
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/docs/PriceCardVariantsDemoPage')
        },
        {
            path: '/Newsletter-Variants-Page/',
            name: 'NewsletterVariantsPage',
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/NewsletterVariantsPageDocs')
        },
        {
            path: '/base-footer/',
            name: 'BaseFooter',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/base-footer/docs/BaseFooterDesignVariationsDemo')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/BaseFooterVariationsDocs')
        },
        {
            path: '/faq-accordion/',
            name: 'FaqAccordion',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/Faq')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/FaqAccordionVariantsDocs')
        },
        {
            path: '/progress-steps/',
            name: 'ProgressSteps',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ProgressSteps')
        },
        {
            path: '/contact-us/',
            name: 'ContactUs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/ContactUsItemsDocsPage')
        },
        {
            path: '/notification-modal/',
            name: 'NotificationModal',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/NotificationModal')
        },
        {
            path: '/alert-modal/',
            name: 'AlertModal',
            component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/AlertModal')
        },
        {
            path: '/progress-circle/',
            name: 'ProgressCircle',
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/ProgressCircleVariantsDocs')
        },
        {
            path: '/styles-theme/',
            name: 'StylesTheme',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/project-basics/StylesThemeDocs')
        },
        // Container
        {
            path: '/card/',
            name: 'CardDocumentation',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/CardDocumentation')
        },
        {
            path: '/grid-row/',
            name: 'RowDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/RowDocs')
        },
        {
            path: '/grid-col/',
            name: 'Col12Docs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/Col12Docs')
        },
        {
            path: '/modal/',
            name: 'Modal',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/Modal')
        },
        {
            path: '/container/',
            name: 'ContainerDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/ContainerDocs')
        },
        {
            path: '/flex/',
            name: 'FlexDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/FlexDocs')
        },
        {
            path: '/tab/',
            name: 'Tab',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/TabsDocs')
        },
        {
            path: '/section/',
            name: 'Section',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/Section')
        },
        {
            path: '/spacing-container/',
            name: 'CustomSpacingContainerDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/CustomSpacingContainerDocs')
        },
        {
            path: '/size-container/',
            name: 'CustomHeightContainerDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/CustomHeightContainerDocs')
        },
        {
            path: '/icon/',
            name: 'IconTextVariationsDocumentation',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/IconTextVariationsDocumentation')
        },
        {
            path: '/team-section-plain/',
            name: 'TeamSectionPlainVariantsDocsPage',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/TeamSectionPlainVariantsDocsPage')
        },
        {
            path: '/team-section-colored/',
            name: 'TeamSectionVariantsDemoPage',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/TeamSectionColoredVariantsDocsPage')
        },
        {
            path: '/banner-section/',
            name: 'BannerSections',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/banner-sections/BannerSectionsDocs')
        },
        {
            path: '/global-registration/',
            name: 'GlobalRegistration',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/project-basics/GlobalComponentsAndPlugins')
        },
        {
            path: '/block-quote/',
            name: 'BlockQuote',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/BlockquoteDocs')
        },
        {
            path: '/social-icons/',
            name: 'SocialIcons',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/SocialIconsDocs')
        },
        // TopNavBar
        {
            path: '/top-nav/',
            name: 'TopNav',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/navigations/TopNav')
        },
        // ui-components
        {
            path: '/tag-item-plain/',
            name: 'TagItemPlainWithBulletVariationsDocumentation',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/TagItemPlainWithBulletVariationsDocumentation')
        },
        {
            path: '/Tag-Item-two-text/',
            name: 'TagItemTwoTextsVariationsDocumentation',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/TagItemTwoTextsVariationsDocumentation')
        },
        {
            path: '/Tag-Item/',
            name: 'TagItemVariantsDocumentation',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/containers/TagItemVariantsDocumentation')
        },
        {
            path: '/side-bar/',
            name: 'SidebarNavigationDocsPage',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/web-components/SidebarNavigationDocsPage')
        },
        {
            path: '/ui-layouts/',
            name: 'UILayouts',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/project-basics/UiLayoutsDocs')
        },
        {
            path: '/click-outside-directive/',
            name: 'ClickOutsideDirective',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/project-basics/ClickOutsideDirectivesDocs')
        },
        {
            path: '/login-pages/',
            name: 'LoginPages',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/project-basics/auth/LoginPages')
        },
        {
            path: '/lego-commands/',
            name: 'LegoCommands',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/project-basics/LegoFrameworkCommands')
        },
        {
            path: '/color-system-docs/',
            name: 'ColorSystemDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/design-system/ColorSystemDocs')
        },
        {
            path: '/typography-docs/',
            name: 'TypographyDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/design-system/TypographyDocs')
        },
        {
            path: '/grid-system-docs/',
            name: 'GridSystemDocs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/helpers/GridSystem')
        },
        {
            path: '/ajax-select-docs/',
            name: 'AjaxSelect',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/AjaxSelect')
        },
        {
            path: '/button-docs/',
            name: 'Button',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/Button')
        },
        {
            path: '/button-groups-docs/',
            name: 'Button Groups',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/ButtonGroupsDocs')
        },
        {
            path: '/checkbox-docs/',
            name: 'Checkbox',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/Chekbox')
        },
        {
            path: '/date-picker-docs/',
            name: 'DatePicker',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/DatePicker')
        },
        {
            path: '/file-input-docs/',
            name: 'FileInput',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/FileInput')
        },
        {
            path: '/input-docs/',
            name: 'Input',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/Input')
        },
        {
            path: '/mask-input-docs/',
            name: 'MaskInput',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/MaskInput')
        },
        {
            path: '/select-docs/',
            name: 'Select',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/Select')
        },
        {
            path: '/textarea-docs/',
            name: 'Textarea',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/TextArea')
        },
        {
            path: '/file-input-customized-docs/',
            name: 'Textarea',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/core-components/FileInputFilenameSeparatedDocs')
        },
        // UI Elements
        {
            path: '/card/',
            name: 'Cards Docs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/ui-elements/CardsDocs')
        },
        {
            path: '/icon-image/',
            name: 'Icon Image Docs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/ui-elements/IconImageDocs')
        },
        {
            path: '/text-element/',
            name: 'Text Element',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/ui-elements/TextElementDocs')
        },
        // SCSS
        {
            path: '/spacers/',
            name: 'Spacers',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Spacers')
        },
        {
            path: '/size-variations/',
            name: 'Sizevariations',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Sizevariations')
        },
        {
            path: '/border-radius/',
            name: 'BorderRadius',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/BorderRadius')
        },
        {
            path: '/base-variables/',
            name: 'BaseVariables',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/BaseVariables')
        },
        {
            path: '/font-weight/',
            name: 'FontWeight',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/FontWeight')
        },
        {
            path: '/fontweight-lineheight/',
            name: 'FontWeightandLineHeight',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/FontWeightandLineHeight')
        },
        {
            path: '/gray-colors/',
            name: 'GrayColors',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/GrayColors')
        },
        {
            path: '/animation/',
            name: 'Animation',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Animation')
        },
        {
            path: '/dropper/',
            name: 'Dropper',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Dropper')
        },
        {
            path: '/headings-and-others/',
            name: 'HeadingsandOthers',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/HeadingsandOthers')
        },
        {
            path: '/theme-colors/',
            name: 'ThemeColors',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/ThemeColors')
        },
        {
            path: '/bg-text/',
            name: 'BgandText',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/BgandText')
        },
        {
            path: '/table-variables/',
            name: 'Tablevariables',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/TableVariables')
        },
        {
            path: '/heights/',
            name: 'Heights',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Heights')
        },
        {
            path: '/buttons/',
            name: 'Buttons',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Buttons')
        },
        {
            path: '/color-variations/',
            name: 'ColorVariations',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/ColorVariations')
        },
        {
            path: '/grid/',
            name: 'Grid',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Grid')
        },
        {
            path: '/custom-spacing-element/',
            name: 'CustomSpacingElements',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/CustomSpacingElements')
        },
        {
            path: '/cards/',
            name: 'Card',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Card')
        },
        {
            path: '/modals/',
            name: 'modals',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Modals')
        },
        {
            path: '/tabs/',
            name: 'Tabs',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Tabs')
        },
        {
            path: '/side-nav-plain/',
            name: 'SideNavPlain',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/SideNavPlain')
        },
        {
            path: '/warning/',
            name: 'Warning',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Warning')
        },
        {
            path: '/top-navbar/',
            name: 'TopNavbar',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/TopNavbar')
        },
        {
            path: '/activity-gauges/',
            name: 'ActivityGauge',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/ActivityGauge')
        },
        {
            path: '/fonts-typography/',
            name: 'Fonts&Typography',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Fonts&Typography')
        },
        {
            path: '/font-items/',
            name: 'FontItems',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/FontItems')
        },
        {
            path: '/custom-height-container/',
            name: 'CustomHeightContainer',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/CustomHeightContainer')
        },
        {
            path: '/side-navbar/',
            name: 'SideNavbar',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/SideNavbar')
        },
        {
            path: '/page-layouts/',
            name: 'PageLayouts',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/PageLayouts')
        },
        {
            path: '/scrollbars/',
            name: 'Scrollbars',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Scrollbars')
        },
        {
            path: '/badge/',
            name: 'Badge',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Badge')
        },
        {
            path: '/typography/',
            name: 'Typography',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Typography')
        },
        {
            path: '/pagination/',
            name: 'Pagination',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Pagination')
        },
        {
            path: '/blog-item-cards/',
            name: 'BlogItemCard',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/BlogItemCard')
        },
        {
            path: '/form-control-floating/',
            name: 'FormControlFloating',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/FormControlFloating')
        },
        {
            path: '/avatar-img/',
            name: 'AvatarImage',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/AvatarImage')
        },
        {
            path: '/tag-items/',
            name: 'TagItem',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/TagItem')
        },
        {
            path: '/forms/',
            name: 'Forms',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Forms')
        },
        {
            path: '/success/',
            name: 'Success',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Success')
        },
        {
            path: '/progress-step/',
            name: 'ProgressSteps',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/ProgressSteps')
        },
        {
            path: '/danger-error/',
            name: 'Danger&Error',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/Danger&Error')
        },
        {
            path: '/variation/',
            name: 'VariationSize',
            // component: () => import(/* webpackChunkName: "home" */'../views/lego-web-components/ContactUs')
            component: () => import(/* webpackChunkName: "home" */'../views/scss/VariationSize')
        }
    ]
};
