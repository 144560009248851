<template>
    <div class="container-fluid bg-2 round-2 p-4">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PageTemplate'
};
</script>

<style scoped>

</style>
